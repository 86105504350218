import React from 'react';
import AboutPic from '../../images/vanvanvanNev-removebgN.png';
import './about.css';

const About = () => {
  return (
    <section className="about" id="about">
      <div className="profile-img">
        <img src={AboutPic} alt="Profile" id="about-profile-pic" />
      </div>
      <div className="about-container">
        <h2>
          About <span>Me</span>
        </h2>

        <div className="text-animate">
          <h3>Full Stack Developer / DevOps Engineer</h3>
        </div>

        <div className="social-media">
          <p>
            My name is Vande Nikolovski. I am a passionate and professional
            <span> Full Stack Developer</span> / <span>DevOps Engineer</span>{' '}
            with more than 2 years of commercial experience. I create high
            standard websites that are modern, fast, and user friendly. I can
            help you build your dream website regardless of your specific
            business requirements. My main experience is in front-end and
            back-end development but I am also equally passionate for design.
          </p>
          <br />
          <p>
            I am producing usable and highly scalable web software solutions. I
            have strong interpersonal skills and proven record of communicating
            with senior executives and stakeholders. I am punctual and reliable
            individual ready to go extra mile to ensure performance standards
            are met. I can also quickly and efficiently join your team using
            continuous-integration methods and tools such as Git and Agile.
          </p>
          <br />
        </div>
        <div>
          <a
            href="CV/Vande Nikolovski CV.pdf"
            className="about-btn-cv"
            download
          >
            Download CV
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
