import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-text">
        <p>
          Copyright &copy; 2023 by <span>Vande Nikolovski</span> | All Right
          Reserved
        </p>
      </div>

      <div className="footer-icon">
        <a href="https://example.com">
          <i className="bx bx-up-arrow-alt"></i>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
